<!--如何在网站上下订单-->
<template>
  <div>
    <div class="websiteorder">
      <!-- 中文 -->
      <div v-if="this.language === 'zh_CN'">
        <h2>1. 如何在网站上下订单：</h2>
        <p>1.1. 选择您要购买的商品。 单击“添加到购物车”，产品将自动添加到您的购物车。</p>
        <p>1.2. 在确认购物车中的购买清单正确无误后，单击“结帐”按钮。 输入有关买家的信息：姓氏、联系电话和电子邮件。 选择送货方式。</p>
        <p>1.3. 选择所需的支付方式：刷卡支付、电子银行支付、取款或其他方式。 然后点击支付；</p>
        <p>重要提示：只有注册用户才能在网上商店购买商品。 在注册之前，买家还必须阅读商店的隐私政策（使用条款）。 买家在商城注册时，即视为已阅读商城隐私政策并同意。 通过填写注册表并提供必要的数据（以下简称 - 注册数据）进行注册。 买方负责注册数据的正确性、保密性和/或安全性。</p>
      </div>
      <!-- 中文 -->
      <div v-else-if="this.language === 'he_IL'">
        <h2>1. ביצוע ההזמנה</h2>
        <p>1.1 בחרו מוצר אותו תרצו לרכוש. לחצו "הוסף לסל", המוצר יתווסף לסל הקניות באופן אוטומטי </p>
        <p>1.2 לאחר שווידאתם את נכונות רשימת הקניות בסל, לחצו על כפתור "לבצע את ההזמנה". הזינו את פרטי הרוכש: שם, מס' טלפון ודוא"ל. בחרו את אופן המסירה</p>
        <p>1.3 בחרו את אופן התשלום הרצוי: כרטיס אשראי, בנקאות דיגיטלית, איסוף עצמי או אחר. לאחר מכן לחצו "לבצע תשלום"</p>
        <p>חשוב לדעת: רק משתמש רשום יכול לרכוש מוצרים בחנות האינטרנט. בטרם ההרשמה על הרוכש לעיין גם במדיניות הסודיות של החנות (תנאי שימוש). הרשמת הרוכש בחנות מהווה אישור כי עיין במדיניות הסודיות של החנות והסכים לתנאיה. ההרשמה מתבצעת ע"י מילוי טופס הרשמה והצגת נתונים נדרשים (להלן - פרטי הרשמה). הרוכש נושא באחריות בגין נכונות, סודיות ו/או שמירת פרטי ההרשמה. </p>
      </div>
      <!-- 英文 -->
      <div v-else-if="this.language === 'en_GB'">
        <h2>1. Order submission:</h2>
        <p>1.1. Select the product you want to purchase. Click "Add to Cart", the item will be automatically added to the cart.</p>
        <p>1.2. After making sure that the list of purchases in the cart is correct, press the "Continue" button. Enter information about the Buyer (name, contact phone number, etc.). Choose a delivery method.</p>
        <p>1.3. Choose the convenient payment method: payment by card, via electronic banking, picking up goods or other methods. After selecting BUY - make the payment;</p>
        <p>IMPORTANT: only registered Users can buy goods in the online store. Before registration, the Buyer must also familiarize himself with the Store's Privacy Policy (Terms of Use). When the buyer registers in the Store, it is considered that he has familiarized himself with the Privacy Policy of the store and agrees with it. Registration is done by filling out the registration form and providing the necessary data (hereinafter - Registration data). The buyer is responsible for the correctness, confidentiality and/or preservation of the Registration data.</p>
      </div>
      <!-- 立陶宛语 -->
      <div v-else-if="this.language === 'lt_LT'">
        <h2>1. Užsakymo pateikimas:</h2>
        <p>1.1. Pasirinkite prekę, kurią norite įsigyti. Spustelėkite „Į krepšelį“, prekė bus automatiškai įtraukta į krepšelį.</p>
        <p>1.2. Įsitikinus, kad pirkinių sąrašas krepšelyje yra teisingas, spauskite mygtuką „Tęsti“. Įveskite informaciją apie Pirkėją (vardas, pavardė, kontaktinį telefono numeri ir t.t). Pasirinkite pristatymo būdą.</p>
        <p>1.3. Pasirinkite norimą atsiskaitymo būdą: mokėjimą kortele, per elektroninę bankininkystę, atsiimant prekes ar kitais būdais. Pasirinkus PIRKTI - atlikite mokėjimą;</p>
        <p>SVARBU: internetinėje parduotuvėje prekes gali pirkti tik registruoti Vartotojei. Prieš registraciją Pirkėjas taip pat privalo susipažinti su Parduotuvės Privatumo politika (Naudojimo sąlygos) . Pirkėjui užsiregistravus Parduotuvėje laikoma, kad jis susipažino su parduotuvės Privatumo politika bei su ja sutinka. Registracija atliekama užpildant registracijos formą ir joje pateikiant būtinus duomenis (toliau – Registracijos duomenys). Pirkėjas pats yra atsakingas už Registracijos duomenų teisingumą, slaptumą ir (ar) išsaugojimą.</p>
      </div>
      <!-- 格鲁吉亚语 -->
      <div v-else-if="this.language === 'ka_GE'">
        <h2>1. შეკვეთის გაფორმება:</h2>
        <p>1.1.	აირჩიეთ პროდუქტი, რომლის შეძენაც გსურთ.
          დააწკაპუნეთ „კალათაში დამატება“
          და საქონელი ავტომატურად დაემატება
          თქვენს კალათაში.</p>
        <p>1.2. მას შემდეგ რაც დაადასტურებთ, რომ თქვენს
          კალათაში არსებული საყიდლების სია სწორია,
          დააწკაპუნეთ ღილაკზე „შეკვეთის განთავსება“.
          შეიყვანეთ ინფორმაცია მყიდველის შესახებ:
          გვარი, საკონტაქტო ტელეფონის ნომერი და
          ელ.ფოსტა. აირჩიეთ მიწოდების მეთოდი.</p>
        <p>1.3. აირჩიეთ სასურველი გადახდის მეთოდი:
          გადახდა ბარათით, ელექტრონული ბანკის
          საშუალებით, თვითაღება ან სხვა მეთოდები.
          შემდეგ დააჭირეთ გადახდა;</p>
        <p>მნიშვნელოვანია: მხოლოდ დარეგისტრირებულ
          მომხმარებლებს შეუძლიათ შეიძინონ საქონელი
          ონლაინ მაღაზიაში. რეგისტრაციამდე მყიდველმა
          ასევე უნდა წაიკითხოს მაღაზიის
          კონფიდენციალურობის პოლიტიკა (გამოყენების პირობები).
          როდესაც მყიდველი დარეგისტრირდება მაღაზიაში,
          ითვლება, რომ მან წაიკითხა და ეთანხმება მაღაზიის
          კონფიდენციალურობის პოლიტიკას. რეგისტრაცია
          ხდება სარეგისტრაციო ფორმის შევსებითა და საჭირო
          მონაცემების (შემდგომში სარეგისტრაციო მონაცემები)
          მიწოდებით. მყიდველი პასუხისმგებელია სარეგისტრაციო
          მონაცემების სიზუსტეზე, კონფიდენციალურობაზე
          და/ან უსაფრთხოებაზე.</p>
      </div>
      <!-- 俄语 -->
      <div v-else>
        <h2>1. Оформление заказа:</h2>
        <p>1.1. Выберите товар, который вы желаете приобрести. Нажмите «Добавить в корзину», товар автоматически добавится в корзину.</p>
        <p>1.2. После того как вы удостоверились, что список покупок в корзине верный, нажмите кнопку "Оформить заказ". Введите информацию о Покупателе: Фамилию, Контактный телефон и Email. Выберите способ доставки.</p>
        <p>1.3. Выберите желаемый способ оплаты: оплата картой, через электронный банкинг, самовывоз или другие способы. После нажмите произвести оплату;</p>
        <p>ВАЖНО: покупать товары в Интернет-магазине могут только зарегистрированные Пользователи. Перед регистрацией Покупатель также должен ознакомиться с Политикой конфиденциальности Магазина (Условиями использования). Когда покупатель регистрируется в Магазине, считается, что он ознакомился с Политикой конфиденциальности магазина и согласен с ней. Регистрация осуществляется путем заполнения регистрационной формы и предоставления необходимых данных (далее – Регистрационные данные). Покупатель несет ответственность за правильность, конфиденциальность и/или сохранность Регистрационных данных.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'WebsiteOrder',
  computed:{
    ...mapGetters([
      'language'
    ])
  }
}
</script>

<style scoped lang="scss">
.websiteorder {
  width: 600px;
  margin:auto;
  text-align: justify;
  p {
    font-size: 1.1rem;
    font-weight:bold;
    line-height: 2rem;
    text-indent: 2em;
  }

}
</style>
